#root {
  height: 100%;
  font-family: var(--font-gene-sans);
  transition: all 0.5s ease-out;
  .left-side-nav {
    a {
      &.ps-menu-button {
        color: white;
        padding-right: 10px;
        font-size: var(--font-size-small);
        background-color: var(--color-secondary-blue);
        &:hover {
          background-color: var(--color-tertiary-blue);
        }
      }
      .ps-menu-label {
        font-family: var(--font-gene-sans-bold);
        white-space: nowrap;
        width: 50px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .ps-submenu-content {
      max-height: 100%;
      background: var(--color-dark-blue);
      overflow: auto;
      a {
        background: transparent;
      }
    }
    .ps-menu-root {
      background-color: var(--color-secondary-blue);
      color: var(--color-white);
      font-family: var(--font-gene-sans);
      height: 100%;
      width: 100%;
      border: 0;
      overflow-x: initial;
      overflow-y: initial;
    }
    .ps-menuitem-root {
      &.active {
        a {
          background-color: var(--color-tertiary-blue);
        }
      }
    }
    .ps-sidebar-root {
      border: 0;
      width: 256px;
      position: fixed;
      top: 0;
      z-index: 10;
      height: 100%;
      &.ps-collapsed {
        width: 60px;
      }
    }
    .ps-sidebar-container {
      width: 100%;
    }
  }
  .ant-checkbox-group {
    display: flex;
    flex-direction: column;
  }

  .ant-table-wrapper .ant-table-row-expand-icon::before {
    display: none;
  }

  .ant-table-wrapper .ant-table-row-expand-icon::after {
    display: none;
  }

  .ant-table-row-expand-icon:active {
    border-color: none;
  }
  .gne__placeholder {
    color: var(--color-gray-3);
  }
  .ant-pagination-next button,
  .ant-pagination-prev button {
    color: var(--color-light-blue-5);
  }

  .ant-card {
    box-shadow: 1px 1px 1px 1px lightgray;

    .ant-card-body {
      padding: 5px;
    }
  }
}

.ant-drawer {
  padding: 15px 0;
  .ant-drawer-close {
    position: absolute;
    right: 0px;
  }
  .ant-drawer-header {
    height: 300px;
  }
  .ant-drawer-body {
    padding: 20px;
  }
  .ant-drawer-title {
    font-size: var(--font-size-medium);
    font-family: var(--font-gene-sans-bold);
  }
}

.ant-float-btn-default .ant-float-btn-body {
  .ant-float-btn-content {
    padding-left: 7px;
    .ant-float-btn-icon {
      width: 130px;
    }
  }
}

.ant-tabs {
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--color-tertiary-blue);
    background-color: var(--bs-nav-tabs-link-active-bg);
    border-color: var(--bs-nav-tabs-link-active-border-color);
    border: 0;
    font-family: var(--font-gene-sans-bold);
  }
  .ant-tabs-ink-bar {
    background: var(--color-tertiary-blue);
    border-bottom: 4px solid var(--color-tertiary-blue);
  }
  .ant-tabs-tab:hover {
    color: var(--color-tertiary-blue);
  }
  .ant-tabs-nav {
    padding-left: 30px;
    padding-right: 30px;
    border-bottom: 1px solid #dee2e6;
  }
  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0px 0 0 14px;
  }
  .ant-tabs-tab {
    font-size: 16px;
    padding: 12px;
  }
}

.ant-typography {
  margin-bottom: 0.15em !important;
}

body,
html {
  margin: 0;
  font-size: 16px;
  position: relative;
  min-height: 100vh;
  font-family: var(--font-gene-sans);
  transition: all 0.5s ease-out;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--color-black);
  padding-top: 48px;
  overflow-x: hidden;
  a {
    text-decoration: none;
    color: var(--color-tertiary-blue);
    &.text-white {
      color: #fff;
    }
    &:hover {
      color: var(--color-tertiary-blue);
    }
  }
}
p {
  font-family: var(--font-gene-sans);
  font-size: var(--font-size-small);
  transition: all 0.5s ease-out;
}
section {
  &.divider {
    border-bottom: 1px solid var(--color-light-brown-2);
  }
}
h5 {
  font-size: var(--font-size-normal);
}
label {
  margin-bottom: 8px;
}
Col,
Container,
Row,
section,
label,
table,
tr,
td {
  transition: all 1s ease-out;
}
hr {
  border-top-color: var(--color-light-brown-2);
}
