.site-performance-container {
  background: var(--color-light-brown);
  margin: 2.5em -40px 2.5em -96px;
  padding: 3em 6em;
  padding-bottom: 1em;
  .site-performance-header {
    display: flex;
  }
}
.radio_button {
  padding: auto;
  margin: 7px 10px 0px 10px;
  align-items: center;
}

.radio_label {
  padding: auto;
  margin: 0px 5px;
  align-items: center;
  accent-color: var(--color-tertiary-blue);
}

.radio_selection {
  padding: auto;
  margin: 0px 20px;
  position: relative;
  z-index: 4;
}
