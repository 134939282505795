.content {
  padding: 40px 40px 40px 96px;
  width: 78%;
  &.iframe {
    padding-left: 55px;
    padding-top: 0;
    margin-top: -40px;
    zoom: 0.96;
  }
}
