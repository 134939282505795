.messageContainer {
  text-align: left; // Text is left-aligned
  width: 20rem;
  position: relative; // For absolute positioning of the button
}
.subMessage {
  font-size: 35px;
  font-family: var(--font-gene-sans-italic);
}
.sessionMessage {
  font-size: 18px;
  font-weight: bold;
  font-family: var(--font-gene-sans);
}
.alertsContainer{
  height: 20rem;
  overflow: auto;
}
.releaseContainer {
  border-radius: 7px;
  margin: 5px;
  padding: 0;
  width: 400px;
}
.releaseIcon {
  position: relative;
  left: 16px;
  top: 0px;
}
.img_width {
  width: 180.91px !important;
  height: 205.01px !important;
}
.devider {
  margin-top: 0rem;
  width: 45vw;
  color: var(--shadow-dropdown);
  float: right;
  border-width: 1px;
}
.notification_date {
  color: var(--color-gray);
  font-size: 12px;
}
