.messageContainer {
  text-align: left; // Text is left-aligned
  position: relative; // For absolute positioning of the button
}
.subMessage {
  font-size: 35px;
  font-family: var(--font-gene-sans-italic);
}
.sessionMessage {
  font-size: 18px;
  color: var(--color-gray-3);
  font-family: var(--font-gene-sans);
}
