.gne-checkbox {
  &.MuiCheckbox-root {
    color: var(--color-gray-3);
    padding-right: 6px;
    &.Mui-checked {
      color: var(--color-tertiary-blue);
    }
    &.Mui-disabled {
      color: var(--color-light-brown-2);
    }
    &.no-padding {
      padding-right: 0;
      color: transparent;
      &.Mui-checked {
        color: var(--color-tertiary-blue);
      }
      &.Mui-disabled {
        color: var(--color-light-brown-2);
      }
      &::before {
        content: '';
        width: 14px;
        height: 14px;
        background-color: var(--color-white);
        border: 1px solid var(--color-light-brown-2);
        border-radius: 2px;
        margin-right: -16px;
      }
    }
  }
}
