.link-list {
  list-style-type: none;
  padding-left: 0;
  li {
    color: var(--color-tertiary-blue);
    font-size: var(--font-size-small);
    margin-bottom: 24px;
    margin-right: 1rem;
    cursor: pointer;
  }
}
