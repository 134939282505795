.addCommentsContainer {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.buttons-wrapper {
  margin-top: 30px;
}

.comment-list-item {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  line-height: 1.5;
}

.comment-text::after {
  content: '...';
}

.comment-text.see-more {
  white-space: unset;
  -webkit-line-clamp: unset;
}

.comment-text.see-more::after {
  white-space: unset;
  -webkit-line-clamp: unset;
}

.commentListTitle {
  display: flex;
  font-family: var(--font-gene-sans-bold);
  color: var(--color-black);
}

.radio_button {
  margin-right: 15px;
}

.radio_label {
  margin: 5px 5px 0 0;
  align-items: center;
}

.radio-button-container {
  margin-top: 10px;
}

.commentType {
  background: #dcdcdc 0% 0% no-repeat padding-box;
  border-radius: 12px;
  opacity: 1;
  display: inline-block;
  text-align: left;
  letter-spacing: 0px;
  color: var(--color-black);
  text-transform: uppercase;
  opacity: 1;
  padding: 0 8px;
  margin-left: 10px;
}

.addListDrawer {
  .ant-drawer-header {
    height: 300px;
  }
  .ant-drawer-body {
    padding: 24px 0;
  }
  .ant-drawer-title {
    font-size: var(--font-size-medium);
    font-family: var(--font-gene-sans-bold);
  }
}

.comments-title {
  font-size: var(--font-size-normal);
  color: var(--color-black);
}

.viewMoreLink {
  padding: 0;
}
.commentFooter {
  text-align: left;
  font: normal normal normal 12px/16px Gene Sans;
  letter-spacing: 0px;
  color: var(--color-gray);
  opacity: 1;
  .commentAuthor {
    padding-left: 10px;
  }
}

.edit-icon {
  margin-right: 5px;
}
.context-menu-button {
  color: var(--color-black);
}
.icon-wrap {
  cursor: pointer;
}
