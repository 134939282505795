.favorites-container {
  top: 228px;
  left: 96px;
}

.stickyTab {
  position: sticky;
  top: 0px;
  z-index: 9;
  overflow: hidden;
  background: var(--color-white);
}
.global-warning {
  display: flexbox;
  align-items: center;
  justify-content: space-between;
  background: #faf6ef 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
}
