.list-item {
  align-items: center;
  border-radius: var(--border-radius);
  border: 1px solid var(--color-light-brown-2);
  padding: 12px 23px;
  margin-bottom: 8px;
  background: var(--color-light-brown);
  display: flex;
  min-height: 65px;
  .row {
    flex-grow: 1;
    align-items: center;
  }
  &.no-bottom-border {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  &.child {
    padding: 8px;
    margin-bottom: 0;
    min-height: 55px;
  }
}

.container-wrap {
  display: flex;
  justify-content: space-between;
}
