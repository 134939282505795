.filter-container {
  border-radius: var(--border-radius);
  background: var(--color-light-brown);
  // margin-bottom: 5px;
  margin-top: 15px;
  padding: 12px 17px 0px;
  transition: all 0.5s ease-out;
  position: relative;
  z-index: 0.5;
  .apply {
    width: 128px;
    margin-top: 30px;
  }
  &::after {
    content: '';
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 20px solid var(--color-light-brown);
    position: absolute;
    top: -10px;
    right: 125px;
  }
}

.filter-container-actions {
  border-radius: var(--border-radius);
  background: var(--color-light-brown);
  width: 340px;
  margin-bottom: 30px;
  padding: 15px;
  transition: all 0.5s ease-out;
  position: relative;
  z-index: 2;
  .apply {
    width: 128px;
    margin-top: 24px;
  }
  &.info {
    margin-top: 50px;
    &::after {
      content: '';
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      border-bottom: 16px solid var(--color-light-brown);
      position: absolute;
      top: -14px;
      right: 34px;
    }
  }
}

.actionsDropdown {
  width: 250px;
}
.filter-dropdownA {
  margin-right: 15px;
  margin-top: 4px;
  margin-bottom: 2px;
  width: 300px;
  height: 0.5rem;
}
.LinkButton_link-button {
  .disabled {
    background-color: var(--color-tertiary-blue);
    border-color: var(--color-tertiary-blue);
  }
}

.isDisabled {
  pointer-events: none;
  opacity: 0.5;
}
.radioSelectionClass {
  width: 180px;
}

.reset-btn {
  color: var(--color-gray-3);
  pointer-events: none;
}

.filter-wrap {
  padding: 0px 10px;
  display: flex;
  justify-content: space-between;
}

.filters-title {
  font-size: var(--font-size-small);
  font-family: var(--font-gene-sans-bold);
}

.radio_button {
  padding: auto;
  margin: 10px 27px 15px 0px;
  align-items: left;
}
.Advance_radio_button {
  padding: auto;
  margin: 7px 3px 21px 0px;
  padding: 2px 1px 2px 1px;
  display: flex;
  white-space: nowrap;
  display: inline-block;
  .radio_trend {
    accent-color: var(--color-tertiary-blue);
    border-color: var(--color-tertiary-blue);
  }
}
.single_select {
  width: 100%;
}

@media only screen and (max-width: 1194px) {
  .advance-radio-btn-ipad-align {
    width: 70%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .advance-kpi-dropdown-ipad-align {
    width: 30%;
    padding-top: 10px;
    padding-bottom: 15px;
  }
  .Advance_radio_button {
    min-width: 45%;
    max-width: 45%;
    margin-bottom: 5px;
    padding-bottom: 5px;
  }
  .filter-container {
    padding-top: 2px;
  }
}
