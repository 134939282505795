.title {
  font-family: var(--font-gene-sans-bold);
  font-size: var(--font-size-xx-small);
}
.detail {
  font-family: var(--font-gene-sans);
  font-size: var(--font-size-xx-small);
  display: inline-block;
  display: flex;
  padding-right: 0;
  white-space: nowrap;
  align-items: center;
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}
