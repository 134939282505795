.link-button {
  border: 0;
  background: transparent;
  color: var(--color-tertiary-blue);
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 1194px) {
  .link-button {
    padding: 1px 5px !important;
  }
}
