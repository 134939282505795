.title-container {
  margin-bottom: 20px;
  display: flex;
  align-items: flex-start;
}
.account-dropdown {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--color-light-brown);
  padding: 15px 30px;
  border-radius: var(--border-radius);
  transition: all 0.5s ease-out;
}
.list-item {
  align-items: center;
}
.list-item-insight {
  align-items: center;
}
.wide {
  width: 400px;
}

.searchbar-container {
  text-align: right;
}

.search-bar {
  width: 300px;
}

.radio_button {
  margin: 7px 10px;
  align-items: center;
  font-size: 16px;
  display: flex;
}

.radio_label {
  padding: auto;
  margin: 0px 5px;
  align-items: center;
  width: 1em;
  height: 1em;
  accent-color: var(--color-tertiary-blue);
}

.radio_selection {
  display: flex;
  width: 100%;
}

.configure-label {
  width: 170px;
}

.customer-insight-label {
  width: 164px;
}

.update-insight-btn-label {
  font-size: 12px;
  justify-content: center;
  display: flex;
  width: 150px;
}

.context-menu-button {
  color: var(--color-black);
}
.dropdown-container {
  width: 35rem;
  margin-left: 110px;
}
.configure-container {
  display: flex;
  width: fit-content;
}
.insights-header-container {
  position: relative;
}

@media only screen and (max-width: 1194px) {
  .account-dropdown {
    padding: 33px 30px;
  }
}
@media (max-width: 1021px) {
  .dropdown-container {
    width: 100%;
    margin-left: 0;
  }
}
