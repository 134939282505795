.site-performance-container {
  background: var(--color-light-brown);
  margin: 2.5em -40px 2.5em -96px;
  padding: 3em 6em;
  padding-bottom: 1em;
  .site-performance-header {
    display: flex;
  }
}

.radio_button {
  padding: auto;
  margin: 7px 10px 0px 10px;
  align-items: center;
}

.radio_label {
  padding: auto;
  margin: 0px 5px;
  align-items: center;
  accent-color: var(--color-tertiary-blue);
}

.radio_selection {
  padding: auto;
  margin: 0px 20px;
  position: relative;
  z-index: 4;
}
.radio_button_btn {
  padding: auto;
  margin: 6px 5px 0px 5px;
  align-items: center;
}
.radio-group {
  display: flex;
  gap: 6px;
  margin-left: -29px;
}
.acc_radio_label {
  margin: 0px 1px;
  accent-color: var(--color-tertiary-blue);
  display: flex;
  margin: 0px 6px;
  margin-left: 3px;
}
.acc_custom_popup {
  width: 209px;
  height: 240px;
  margin-left: 2px;
  .checkboxGroup {
    margin-top: 3px;
    display: flex;
    flex-direction: column;
    .ant-checkbox-wrapper {
      padding: 10px;
    }
  }
  .checkboxWrapper {
    margin: 5px 0;
  }
}
.acc-verbiag{
  margin-left: 3px;
}

