.content-container {
  padding: 10px;
}

.remove-icon-wrap {
  width: 19px;
  height: 19px;
}

.title-wrap {
  margin: 3px 0px 0px 3px;
  font-size: var(--font-size-medium);
  font-family: var(--font-gene-sans-bold);
}

.subtitle-wrap {
  font-family: var(--font-gene-sans);
  font-size: var(--font-size-small);
  padding-top: 8px;
}

.dropdown-wrap {
  margin: 30px 0 10px 0;
}

.label-wrap {
  margin-top: 20px;
}

.save-btn {
  width: 77px;
}
