/* scrollbar */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb:window-inactive {
  background: var(--color-light-brown-2) 0% 0% no-repeat padding-box;
}

*::-webkit-scrollbar-track {
  background: var(--color-light-brown-2);
  -webkit-border-radius: 10px;
}

*::-webkit-scrollbar-thumb {
  background: var(--color-gray);
  border-radius: 20px;
  border: 3px solid var(--scroll-bar-bg-color);
}
