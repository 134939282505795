.gne-container {
  position: relative;
  .gne-card {
    border-radius: var(--border-radius);
    padding: 16px;
    box-shadow: var(--shadow-card);
    display: block;
    background-color: #fff;
  }
  .gne-tile {
    height: 330px;
    min-height: 330px;
    max-height: 330px;
  }
  .card {
    border: 0;
  }
  .card-body {
    z-index: 0;
    position: relative;
    background: var(--color-white) 0% 0% no-repeat padding-box;
    box-shadow: var(--shadow-card);
    border-radius: var(--border-radius);
    padding: 24px;
    .title {
      font-size: var(--font-size-normal);
    }
  }
  .board-card {
    display: flex;
    flex-direction: column;
    padding: 8px 20px;
    overflow: hidden;
  }
  .list-container {
    display: flex;
    flex-direction: row;
  }
  .list-card {
    border-radius: var(--border-radius);
    border: 1px solid var(--color-light-brown-2);
    padding: 12px;
    margin-bottom: 8px;
    background: #fff;
    display: flex;
    min-height: 65px;
    .row {
      flex-grow: 1;
      align-items: center;
    }
    &.no-bottom-border {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    &.child {
      padding: 8px;
      margin-bottom: 0;
      min-height: 55px;
    }
  }

  .list-content {
    background: var(--color-light-brown);
    border-radius: var(--border-radius);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border: 1px solid var(--color-light-brown-2);
    border-top: 0;
    padding: 15px 15px 0;
    width: 98%;
    margin-left: 2%;
    margin-top: -10px;
    display: flex;
    flex-direction: row;
    &:first-child {
      padding-top: 20px;
    }
    &:last-child {
      .list-card {
        &.child {
          margin-bottom: 20px;
        }
      }
    }
    &.nested {
      padding-left: 4%;
    }
  }
  .content-wrapper {
    margin-bottom: 8px;
  }
  .icon-container {
    width: 2%;
    padding-left: 0;
  }
  .list-card-head {
    width: 98%;
    label {
      margin-bottom: 0;
    }
    row {
      align-items: center;
    }
  }
}
