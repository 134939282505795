.gne-success {
  font-size: var(--font-size-small);
  font-family: var(--font-gene-sans-bold);
  color: var(--color-green);
  width: 350px;
  position: relative;
  &__my-view {
    position: absolute;
    right: 40px;
    top: -26px;
    .alert-dismissible {
      position: relative;
      margin-bottom: 0;
      .btn-close {
        top: 0;
        bottom: 0;
        margin: auto 0;
        position: absolute;
        font-family: var(--font-gene-condensed-bold);
      }
    }
  }
}
