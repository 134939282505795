.chart-container {
  position: relative;
  display: flex;
  margin-left: 0px;
}
.table-chart-container {
  height: 61vh;
  width: 100%;
  overflow-y: hidden;
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &.ant-table-body {
    overflow-y: hidden !important;
  }
  &::-webkit-scrollbar {
    display: none;
  }
}

@media (min-height: 768px) and (orientation: landscape) {
  /* Styles for landscape mode with height greater than 768px */
  .table-chart-container {
    height: 52vh;
  }
}

.chart-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.table-container {
  height: 300px;
  width: 100%;
}

.configure {
  align-items: center;
  justify-content: center;
  display: flex !important;
  color: #fff;

  &.active {
    background: #fff;
    color: var(--color-tertiary-blue);
  }
  &__disable {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    background-color: #dcdcdc !important;
    border-color: #dcdcdc !important;
    color: #fff !important;
    cursor: not-allowed;
  }
}

.icon__configure-active {
  color: #fff;
}
