.releaseContainer {
  background: var(--color-light-brown);
  border-radius: 7px;
  margin: 5px;
  padding: 10px 0 0 0;
}
.releaseIcon {
  position: relative;
  left: 16px;
  top: 18px;
}
