.org {
  transition: all 1s ease-out;
}
.account-wrapper {
  width: 56%;
}
.top-filter-panel {
  gap: 5px;
}
.account {
  width: 400px;
}

.actionsDropdown {
  width: 200px;
  margin-left: 4rem;
}
.filter {
  width: 110px;
  display: flex !important;
  align-items: center;
  transition: all 1s ease-out;
  .count {
    background: var(--color-tertiary-blue);
    color: #fff;
    border-radius: 20px;
    width: 18px;
    height: 18px;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--font-size-x-small);
    margin-right: 6px;
    &.active {
      background: #fff;
      color: var(--color-tertiary-blue);
    }
  }
}

.comments {
  position: absolute;
  right: 0;
  top: 10px;
  cursor: pointer;
}
.default-message {
  font-family: var(--font-gene-sans-bold);
  font-size: var(--font-size-base);
  text-align: center;
  margin-top: 156px;
}
.info-icon {
  padding-left: 10px;
}
.commentsDropdown {
  font-size: 10px;
}

.comments-dropdown {
  margin-right: 10px;
  margin-top: 8px;
  margin-bottom: 5px;
  margin-left: 5px;
  width: 130px;
  font-size: var(--font-size-small);
  font-weight: 100;
}

.add-btn-class {
  height: 35px;
  margin-top: 6px;
}

.drawer-title-container {
  display: flex;
  align-items: center;
}

.tab-title {
  font-size: var(--font-size-medium);
  width: 200px;
  padding: 8px 0 0 5px;
}
.toolbar {
  padding: 6px;
  display: flex;
  align-self: flex-end;
}
.capitalize {
  text-transform: capitalize;
}

.tab-custom-class {
  border-bottom: 4px solid var(--color-tertiary-blue);
  color: var(--color-tertiary-blue);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
  border: 0;
  font-family: var(--font-gene-sans-bold);
}

.legend-display {
  padding: 3px;
  font-size: xx-small;
}

.legends-div {
  width: 10px;
  height: 10px;
  margin: 7px 0px 0px 13px;
  border-radius: 5px;
}
.radio_selection {
  display: flex;
}
.radio_button {
  margin: 7px 10px;
  align-items: center;
  font-size: 16px;
  display: flex;
}

.radio_label {
  padding: auto;
  margin: 0px 5px;
  align-items: center;
  width: 1em;
  height: 1em;
  accent-color: var(--color-tertiary-blue);
}
.wide {
  width: 350px;
}
