.content-container {
  padding: 10px;
}

.icon-wrap {
  width: 25px;
  height: 20px;
}

.title-wrap {
  margin: 3px 0px 0px 3px;
  font-size: var(--font-size-medium);
  font-family: var(--font-gene-sans-bold);
}

.subtitle-wrap {
  font-family: var(--font-gene-sans);
  font-size: var(--font-size-small);
  padding-top: 8px;
}

.textbox-wrap {
  margin: 10px 0 20px 0;
}

.textbox-title {
  font-size: var(--font-size-x-small);
  padding-bottom: 6px;
  font-family: var(--font-gene-sans);
}

.mandatory-icon-style {
  color: var(--color-red-2);
}

.error-message {
  border: 1px solid var(--color-red-2);
}

.radio-wrap {
  display: flex;
  padding-top: 25px;
  align-items: center;
}

.radio-wrap-title {
  font-family: var(--font-gene-sans-bold);
  padding-right: 8px;
  font-size: var(--font-size-small);
}
