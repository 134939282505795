@font-face {
  font-family: 'Gene-Condensed-Bold';
  font-display: swap;
  src: url('https://fonts.gene.com/genentech/Gene-Condensed-Bold.eot');
  src: url('https://fonts.gene.com/genentech/Gene-Condensed-Bold.eot')
      format('eot'),
    url('https://fonts.gene.com/genentech/Gene-Condensed-Bold.woff2')
      format('woff2'),
    url('https://fonts.gene.com/genentech/Gene-Condensed-Bold.woff')
      format('woff'),
    url('https://fonts.gene.com/genentech/Gene-Condensed-Bold.ttf')
      format('truetype');
}

@font-face {
  font-family: 'Gene-Condensed-Heavy';
  font-display: swap;
  src: url('https://fonts.gene.com/genentech/Gene-Condensed-Heavy.eot');
  src: url('https://fonts.gene.com/genentech/Gene-Condensed-Heavy.eot')
      format('eot'),
    url('https://fonts.gene.com/genentech/Gene-Condensed-Heavy.woff2')
      format('woff2'),
    url('https://fonts.gene.com/genentech/Gene-Condensed-Heavy.woff')
      format('woff'),
    url('https://fonts.gene.com/genentech/Gene-Condensed-Heavy.ttf')
      format('truetype');
}

@font-face {
  font-family: 'Gene-Condensed-Light';
  font-display: swap;
  src: url('https://fonts.gene.com/genentech/Gene-Condensed-Light.eot');
  src: url('https://fonts.gene.com/genentech/Gene-Condensed-Light.eot')
      format('eot'),
    url('https://fonts.gene.com/genentech/Gene-Condensed-Light.woff2')
      format('woff2'),
    url('https://fonts.gene.com/genentech/Gene-Condensed-Light.woff')
      format('woff'),
    url('https://fonts.gene.com/genentech/Gene-Condensed-Light.ttf')
      format('truetype');
}

@font-face {
  font-family: 'Gene-Condensed';
  font-display: swap;
  src: url('https://fonts.gene.com/genentech/Gene-Condensed-Regular.eot');
  src: url('https://fonts.gene.com/genentech/Gene-Condensed-Regular.eot')
      format('eot'),
    url('https://fonts.gene.com/genentech/Gene-Condensed-Regular.woff2')
      format('woff2'),
    url('https://fonts.gene.com/genentech/Gene-Condensed-Regular.woff')
      format('woff'),
    url('https://fonts.gene.com/genentech/Gene-Condensed-Regular.ttf')
      format('truetype');
}

@font-face {
  font-family: 'Gene-Sans-Bold-Italic';
  font-display: swap;
  src: url('https://fonts.gene.com/genentech/Gene-Sans-Bold-Italic.eot');
  src: url('https://fonts.gene.com/genentech/Gene-Sans-Bold-Italic.eot')
      format('eot'),
    url('https://fonts.gene.com/genentech/Gene-Sans-Bold-Italic.woff2')
      format('woff2'),
    url('https://fonts.gene.com/genentech/Gene-Sans-Bold-Italic.woff')
      format('woff'),
    url('https://fonts.gene.com/genentech/Gene-Sans-Bold-Italic.ttf')
      format('truetype');
}

@font-face {
  font-family: 'Gene-Sans-Bold';
  font-display: swap;
  src: url('https://fonts.gene.com/genentech/Gene-Sans-Bold.eot');
  src: url('https://fonts.gene.com/genentech/Gene-Sans-Bold.eot') format('eot'),
    url('https://fonts.gene.com/genentech/Gene-Sans-Bold.woff2') format('woff2'),
    url('https://fonts.gene.com/genentech/Gene-Sans-Bold.woff') format('woff'),
    url('https://fonts.gene.com/genentech/Gene-Sans-Bold.ttf')
      format('truetype');
}

@font-face {
  font-family: 'Gene-Sans-Light-Italic';
  font-display: swap;
  src: url('https://fonts.gene.com/genentech/Gene-Sans-Light-Italic.eot');
  src: url('https://fonts.gene.com/genentech/Gene-Sans-Light-Italic.eot')
      format('eot'),
    url('https://fonts.gene.com/genentech/Gene-Sans-Light-Italic.woff2')
      format('woff2'),
    url('https://fonts.gene.com/genentech/Gene-Sans-Light-Italic.woff')
      format('woff'),
    url('https://fonts.gene.com/genentech/Gene-Sans-Light-Italic.ttf')
      format('truetype');
}

@font-face {
  font-family: 'Gene-Sans-Light';
  font-display: swap;
  src: url('https://fonts.gene.com/genentech/Gene-Sans-Light.eot');
  src: url('https://fonts.gene.com/genentech/Gene-Sans-Light.eot') format('eot'),
    url('https://fonts.gene.com/genentech/Gene-Sans-Light.woff2')
      format('woff2'),
    url('https://fonts.gene.com/genentech/Gene-Sans-Light.woff') format('woff'),
    url('https://fonts.gene.com/genentech/Gene-Sans-Light.ttf')
      format('truetype');
}

@font-face {
  font-family: 'Gene-Sans-Italic';
  font-display: swap;
  src: url('https://fonts.gene.com/genentech/Gene-Sans-Regular-Italic.eot');
  src: url('https://fonts.gene.com/genentech/Gene-Sans-Regular-Italic.eot')
      format('eot'),
    url('https://fonts.gene.com/genentech/Gene-Sans-Regular-Italic.woff2')
      format('woff2'),
    url('https://fonts.gene.com/genentech/Gene-Sans-Regular-Italic.woff')
      format('woff'),
    url('https://fonts.gene.com/genentech/Gene-Sans-Regular-Italic.ttf')
      format('truetype');
}

@font-face {
  font-family: 'Gene-Sans';
  font-display: swap;
  src: url('https://fonts.gene.com/genentech/Gene-Sans-Regular.eot');
  src: url('https://fonts.gene.com/genentech/Gene-Sans-Regular.eot')
      format('eot'),
    url('https://fonts.gene.com/genentech/Gene-Sans-Regular.woff2')
      format('woff2'),
    url('https://fonts.gene.com/genentech/Gene-Sans-Regular.woff')
      format('woff'),
    url('https://fonts.gene.com/genentech/Gene-Sans-Regular.ttf')
      format('truetype');
}

@font-face {
  font-family: 'Gene-Sans-Thin-Italic';
  font-display: swap;
  src: url('https://fonts.gene.com/genentech/Gene-Sans-Thin-Italic.eot');
  src: url('https://fonts.gene.com/genentech/Gene-Sans-Thin-Italic.eot')
      format('eot'),
    url('https://fonts.gene.com/genentech/Gene-Sans-Thin-Italic.woff2')
      format('woff2'),
    url('https://fonts.gene.com/genentech/Gene-Sans-Thin-Italic.woff')
      format('woff'),
    url('https://fonts.gene.com/genentech/Gene-Sans-Thin-Italic.ttf')
      format('truetype');
}

@font-face {
  font-family: 'Gene-Sans-Thin';
  font-display: swap;
  src: url('https://fonts.gene.com/genentech/Gene-Sans-Thin.eot');
  src: url('https://fonts.gene.com/genentech/Gene-Sans-Thin.eot') format('eot'),
    url('https://fonts.gene.com/genentech/Gene-Sans-Thin.woff2') format('woff2'),
    url('https://fonts.gene.com/genentech/Gene-Sans-Thin.woff') format('woff'),
    url('https://fonts.gene.com/genentech/Gene-Sans-Thin.ttf')
      format('truetype');
}

@font-face {
  font-family: 'Gene-Serif-Bold-Italic';
  font-display: swap;
  src: url('https://fonts.gene.com/genentech/Gene-Serif-Bold-Italic.eot');
  src: url('https://fonts.gene.com/genentech/Gene-Serif-Bold-Italic.eot')
      format('eot'),
    url('https://fonts.gene.com/genentech/Gene-Serif-Bold-Italic.woff2')
      format('woff2'),
    url('https://fonts.gene.com/genentech/Gene-Serif-Bold-Italic.woff')
      format('woff'),
    url('https://fonts.gene.com/genentech/Gene-Serif-Bold-Italic.ttf')
      format('truetype');
}

@font-face {
  font-family: 'Gene-Serif-Bold';
  font-display: swap;
  src: url('https://fonts.gene.com/genentech/Gene-Serif-Bold.eot');
  src: url('https://fonts.gene.com/genentech/Gene-Serif-Bold.eot') format('eot'),
    url('https://fonts.gene.com/genentech/Gene-Serif-Bold.woff2')
      format('woff2'),
    url('https://fonts.gene.com/genentech/Gene-Serif-Bold.woff') format('woff'),
    url('https://fonts.gene.com/genentech/Gene-Serif-Bold.ttf')
      format('truetype');
}

@font-face {
  font-family: 'Gene-Serif-Light-Italic';
  font-display: swap;
  src: url('https://fonts.gene.com/genentech/Gene-Serif-Light-Italic.eot');
  src: url('https://fonts.gene.com/genentech/Gene-Serif-Light-Italic.eot')
      format('eot'),
    url('https://fonts.gene.com/genentech/Gene-Serif-Light-Italic.woff2')
      format('woff2'),
    url('https://fonts.gene.com/genentech/Gene-Serif-Light-Italic.woff')
      format('woff'),
    url('https://fonts.gene.com/genentech/Gene-Serif-Light-Italic.ttf')
      format('truetype');
}

@font-face {
  font-family: 'Gene-Serif-Light';
  font-display: swap;
  src: url('https://fonts.gene.com/genentech/Gene-Serif-Light.eot');
  src: url('https://fonts.gene.com/genentech/Gene-Serif-Light.eot')
      format('eot'),
    url('https://fonts.gene.com/genentech/Gene-Serif-Light.woff2')
      format('woff2'),
    url('https://fonts.gene.com/genentech/Gene-Serif-Light.woff') format('woff'),
    url('https://fonts.gene.com/genentech/Gene-Serif-Light.ttf')
      format('truetype');
}

@font-face {
  font-family: 'Gene-Serif-Italic';
  font-display: swap;
  src: url('https://fonts.gene.com/genentech/Gene-Serif-Regular-Italic.eot');
  src: url('https://fonts.gene.com/genentech/Gene-Serif-Regular-Italic.eot')
      format('eot'),
    url('https://fonts.gene.com/genentech/Gene-Serif-Regular-Italic.woff2')
      format('woff2'),
    url('https://fonts.gene.com/genentech/Gene-Serif-Regular-Italic.woff')
      format('woff'),
    url('https://fonts.gene.com/genentech/Gene-Serif-Regular-Italic.ttf')
      format('truetype');
}

@font-face {
  font-family: 'Gene-Serif';
  font-display: swap;
  src: url('https://fonts.gene.com/genentech/Gene-Serif-Regular.eot');
  src: url('https://fonts.gene.com/genentech/Gene-Serif-Regular.eot')
      format('eot'),
    url('https://fonts.gene.com/genentech/Gene-Serif-Regular.woff2')
      format('woff2'),
    url('https://fonts.gene.com/genentech/Gene-Serif-Regular.woff')
      format('woff'),
    url('https://fonts.gene.com/genentech/Gene-Serif-Regular.ttf')
      format('truetype');
}
