.engagements-container {
  background: var(--color-white);
  padding-bottom: 1em;

  .engagements-tab-container {
    // margin: 0 -96px;
    // padding: 0 6em;
    .engagements-header {
      display: flex;
    }
    .nav-tabs {
      position: absolute;
      top: 60%;
      right: 0%;
    }
  }
  .engagements-header {
    display: flex;
    justify-content: space-between;
  }
}
