.content-container {
  padding: 10px;
}

.remove-icon-wrap {
  width: 17px;
  height: 19px;
}

.title-wrap {
  margin: 3px 0px 0px 3px;
  font-size: var(--font-size-medium);
  font-family: var(--font-gene-sans-bold);
}

.subtitle-wrap {
  font-family: var(--font-gene-sans);
  font-size: var(--font-size-small);
  padding-top: 8px;
}

.site-title {
  padding: 0 10px;
  font-family: var(--font-gene-sans-bold);
}

.sitename-wrap {
  display: flex;
  border: 1px solid var(--color-red-2);
  border-radius: var(--border-radius);
  padding: 10px;
  margin-top: 20px;
}

.info-icon {
  margin-top: 5px;
}

.warning-box {
  display: flex;
  padding: 10px 20px;
  background: var(--color-warning-yellow);
  margin-top: 20px;
  border-radius: var(--border-radius);
}

.warning-icon-wrap {
  margin: 10px 15px 10px 0px;
  height: 18px;
  width: 20px;
}

.warning-message {
  font-family: var(--font-gene-sans);
  font-size: var(--font-size-small);
  display: flex;
  align-items: center;
}

.textbox-wrap {
  margin: 20px 0;
}

.textbox-title {
  font-size: var(--font-size-x-small);
  padding-bottom: 6px;
  font-family: var(--font-gene-sans);
}

.mandatory-icon-style {
  color: var(--color-red-2);
}

.error-message {
  border: 1px solid var(--color-red-2);
}

.popup-wrap {
  margin-right: 3px;
}
