.info-icon {
  margin-left: 5px;
}
.radio_button {
  margin: 7px 5px 0px 5px;
  align-items: center;
}

.radio-btn-wrapper {
  align-self: flex-end;
  padding: 0 !important;
}

.radio_trend {
  padding: auto;
  align-items: center;
  width: 1em;
  height: 1em;
}

.radio_selection {
  display: flex;
}

.tag-wrap {
  font-size: var(--font-size-small);
  display: flex;
  justify-content: space-between;
  height: 100px;
}
.site-tag {
  display: flex;
  align-items: center;
  height: 48px;
  border-radius: 4px;
  border: 1px solid var(--color-light-brown-2);
  justify-content: space-between;
  padding: 12px;
  width: 100%;
  font-size: var(--font-size-small);
  &.selected {
    background: var(--color-yellow);
  }
  &.no-selection-site-tag {
    border: 2px dashed var(--color-gray-3);
  }
}

.message-align-wrap {
  margin-top: 20%;
  margin-left: 35%;
}
.message-align-wrap-account {
  margin-top: 30%;
  margin-left: 30%;
  font-size: var(--font-size-base);
  font-family: var(--font-gene-sans-bold);
  width: 300px;
}
.title-wrap {
  font-family: var(--font-gene-sans-bold);
  font-size: var(--font-size-medium);
  margin: 6px 0;
}

.subtitle-wrap {
  font-family: var(--font-gene-sans);
}

.reasonPanelHeight {
  height: '500px';
}

.default-message {
  font-size: var(--font-size-base);
  font-family: var(--font-gene-sans-bold);
  margin-left: 40px;
}

.selectionLayout {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.selected-site-wrap {
  font-family: var(--font-gene-sans);
  left: 0px;
}

.previous-button-styles {
  width: 90;
  align-items: 'center';
}
.button-wrapper {
  position: absolute;
  right: 0;
}
