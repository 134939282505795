.customPopup {
  .checkboxGroup {
    display: flex;
    flex-direction: column;
    .ant-checkbox-wrapper {
      padding: 10px;
    }
  }
  .checkboxWrapper {
    margin: 5px 0;
  }
}

.nextButton {
  height: 32px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dcdcdc;
  border-radius: var(--border-radius);
  opacity: 1;
}

.ant-pagination-item-active {
  border-color: var(--color-light-blue-5);
}
