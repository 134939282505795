.affiliations-container {
  margin: 2.5em -40px 2.5em -96px;
  padding: 3em 6em;
  padding-bottom: 1em;

  .affiliations-tab-container {
    // margin: 2.5em -96px;
    // padding: 0 6em;
    .affiliations-header {
      display: flex;
    }
    .nav-tabs {
      position: absolute;
      top: 60%;
      right: 0%;
    }
  }
  .affiliations-header {
    display: flex;
    justify-content: space-between;
  }
}
.affiliation-cta {
  text-align: right;
  padding-top: 5px;
}
