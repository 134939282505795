.selectedList {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.btnBgColor {
  background-color: #004677 !important;
  a {
    color: white !important;
  }
}

.custom-row-padding {
  width: 320px;
}
.custom-row-text {
  font-size: 12px;
  display: flex;
  justify-content: flex-end;
}

.subtitle {
  font-size: 15px;
  font-weight: 500;
}
.fixSize {
  width: 100%;
  overflow-y: scroll;
}
.column-names {
  width: 90%;
  height: 20px;
}
.column-checkbox {
  width: 10%;
  height: 20px;
}
