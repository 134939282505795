.messageContainer {
  text-align: left; // Text is left-aligned
  width: 20rem;
  position: relative; // For absolute positioning of the button
}
.subMessage {
  font-size: 35px;
  font-family: var(--font-gene-sans-italic);
}
.sessionMessage {
  font-size: 18px;
  font-weight: bold;
  font-family: var(--font-gene-sans);
}
.alertsContainer{
  min-height: 13rem;
  max-height: 20rem;
  overflow: auto;
}
.releaseContainer {
  background: var(--color-warning-yellow);
  border-radius: 7px;
  margin: 5px;
  padding: 10px 0 0 0;
  width: 505px;
}
.releaseContainerHeader{
  background: var(--color-warning-yellow);
  border-radius: 7px;
  margin: 5px;
  padding: 10px 0 0 0;
  width: 400px;
}
.releaseIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  margin-left:8px;
  padding: 1rem;

}
.img_width {
  width: 180.91px !important;
  height: 205.01px !important;
}
