.create-label-wrap {
  padding: 0 5px;
}

.error-message {
  width: 300px;
  padding-top: 2px;
  color: var(--color-red-2);
  font-size: var(--font-size-x-small);
  font-family: var(--font-gene-sans);
}

.actions-wrap {
  display: flex;
  justify-content: center;
}

.site-tag {
  display: flex;
  height: 48px;
  background: var(--color-yellow);
  border: 1px solid var(--color-light-brown-2);
  border-radius: 4px;
  justify-content: space-between;
  padding: 12px;
}

.apply-btn {
  height: 33px;
  width: 90px;
  margin-top: 6px;
}

.disabled-btn {
  @extend .apply-btn;
  cursor: 'none';
}

.info-icon {
  padding-left: 10px;
}

.default-message {
  font-size: var(--font-size-base);
  font-family: var(--font-gene-sans-bold);
  margin-left: 40px;
}

.message-align-wrap {
  margin-top: 20%;
  margin-left: 35%;
}

.assign-label-icon {
  margin: 5px 10px 0px 0px;
  width: 20px;
  height: 20px;
}

.dropdown-wrap {
  display: flex;
  padding-top: 10px;
}

.dropdown-title {
  font-family: var(--font-gene-sans-bold);
  font-size: var(--font-size-small);
  padding: 8px 15px 0 0;
}

.dropdown-input {
  width: 200px;
}

.clear-btn {
  color: var(--color-gray-3);
  pointer-events: none;
}

.labels-wrap {
  padding: 4px 0px 0px 16px;
}

.tag-title {
  font-family: var(--font-gene-sans-bold);
  padding-right: 10;
}

.filter-align {
  margin-right: 20px;
}

.table-align {
  width: 100%;
}
