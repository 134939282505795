.defaultMessageHeading {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  font-family: var(--font-gene-sans-bold);
}
.defaultMessageText {
  font-size: 14px;
  text-align: center;
}
.defaultMessage {
  margin: 100px auto;
}
