.ratings-circle {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  font-size: var(--font-size-normal);
  font-family: var(--font-gene-sans-bold);
  background-color: var(--color-light-brown);
  border: 1px solid var(--color-light-brown-2);
  border-radius: 50%;
  margin-right: 8px;
  cursor: pointer;
  &.active {
    background-color: var(--color-tertiary-blue);
    color: #fff;
  }
  &.last {
    margin-right: 0;
  }
}
