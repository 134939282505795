.modal-subtitle {
  font-size: var(--font-size-small);
  font-family: var(--font-gene-sans);
}

.input-container {
  display: flex;
  margin-top: 35px;
}

.label-input {
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  font-size: var(--font-size-x-small);
}

.label-editinput {
  width: 300px;
  margin-top: 5px;
}

.color-editinput {
  width: 100px;
  margin-top: 5px;
}

.create-btn {
  margin-top: 22px;
  width: 90px;
}

.table-container {
  margin: 35px 0px;
}

.dropdown-option {
  margin-top: 5px;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: inline-block;
}

.create-label-wrap {
  padding: 0 5px;
}

.error-message {
  color: var(--color-red);
  font-size: var(--font-size-x-small);
}

.actions-wrap {
  display: flex;
  justify-content: center;
}
