.editMetricTitle {
  font-size: 20px;
}
.configure-cta {
  font-size: var(--font-size-small);
  float: right;
  margin-bottom: 24px;
  font-family: var(--font-gene-sans-bold);
}
.padding-0 {
  padding-left: 0 !important;
}
.ecosystembgcolor {
  background-color: grey;
}
.disabledDropdown {
  background-color: var(--color-disabled-gray);
}
.filter-container {
  padding-left: 20px;
}
.insights-container {
  padding-top: 1.5em !important;
}
.info-icon {
  margin-left: 5px;
}
.radio_button {
  // padding: auto;
  margin: 7px 0px 0px 5px;
  align-items: center;
}
.trends.trendsAccount {
  margin-right: 0;
  width: 380px;
}
.space-label {
  display: block;
  margin-bottom: 12px;
}
.add-button {
  width: 67%;
}
.radio-btn-wrapper {
  align-self: flex-end;
  padding: 0 !important;
}
.productsAccountable {
  margin-left: 5rem;
}

.radio_trend {
  padding: auto;
  align-items: center;
  width: 1em;
  height: 1em;
  background-color: var(--color-tertiary-blue);
  border-color: var(--color-tertiary-blue);
  accent-color: var(--color-tertiary-blue);
}

.radio_selection {
  display: flex;
  .radio_trend {
    background-color: var(--color-tertiary-blue);
    border-color: var(--color-tertiary-blue);
  }
}

.configure-apply {
  display: flex;
  margin-top: 30px;
}

.LinkButton_link-button {
  .disabled {
    background-color: var(--color-tertiary-blue);
    border-color: var(--color-tertiary-blue);
  }
}

.label-inputA {
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  font-size: var(--font-size-x-small);
  font-family: var(--font-gene-sans);
  // border-color: var(--color-tertiary-blue);
  // border: 1px solid var(--color-red-2);
  background-color: var(--color-tertiary-blue);
  border-color: var(--color-tertiary-blue);
  input[type='text']:focus {
    border-color: var(--color-tertiary-blue);
  }
}

.form-control {
  border-right: 0 !important;
  box-shadow: none !important;
  // border: 0 !important;
  border-radius: 0.375rem 0 0 0.375rem !important;
  width: 76%;
  height: 32px;
}
.text-filed-right-border {
  border-right: 0 !important;
  box-shadow: none !important;
  // border: 0 !important;
  border-radius: 0.375rem 0 0 0.375rem !important;
}
.kpi-filter {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.table-chart-container {
  display: flex;
  margin-left: 4px;
}
.local-filter {
  height: 20rem !important;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 5px !important;
    height: 5px !important;
  }
}
.title-name {
  max-width: calc(100% - 70px);
  text-overflow: ellipsis;
  word-break: keep-all;
  overflow: hidden;
  white-space: nowrap;
}

@media (min-width: 992px) {
  .filter-container {
    padding-left: 0px;
  }
}
