.ant-switch-checked {
  background: #74aa50 !important;
  background-color: #74aa50 !important;
}
.ant-switch:not(.ant-switch-disabled),
.ant-switch:hover:not(.ant-switch-disabled) {
  background: var(--color-gray-3);
}

:where.ant-switch {
  background: var(--color-gray-3);
  background-color: var(--color-gray-3);
}
