.insights-container {
  padding-bottom: 1em;
  min-height: 400px;
}

.insights-content {
  background: var(--color-light-brown);
  margin: 1em -9%;
  padding: 0 8%;
  min-height: inherit;
}
