.gne-header {
  height: 48px;
  width: 100vw;
  background: transparent var(--gradient-primary) 0% 0% no-repeat padding-box;
  display: flex;
  align-items: center;
  color: var(--color-white);
  padding-left: 80px;
  position: absolute;
  top: 0;
  .links {
    color: #fff;
  }
}
.header-cta {
  display: flex;
  justify-content: flex-end;
  .cta {
    display: flex;
    align-items: center;
    margin-right: 20px;
  }
  .alignment {
    margin-right: 20px;
  }
}
.userNamePosition {
  margin-top: 0.5rem;
  height: 2rem;
  text-align: center;  
}
.roleCode{
  font-size: var(--font-size-x-small);
  color: var(--color-gray);
}
.userNameInitialPosition {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
}
.circleForUserNameInitialInPopUp {
  height: 24px;
  width: 24px;
  background-color: var(--color-primary-blue);
  font-size: 14px;
  color: var(--color-white);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.circleForUserNameInitialOnHeader {
  height: 24px;
  width: 24px;
  background-color: var(--color-white);
  color: var(--color-primary-blue);
  font-size: 12px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.messageContainer {
  text-align: left; // Text is left-aligned
  width: 20rem;
  position: relative; // For absolute positioning of the button
}
.subMessage {
  font-size: 35px;
  font-family: var(--font-gene-sans-italic);
}
.sessionMessage {
  font-size: 18px;
  font-weight: bold;
  font-family: var(--font-gene-sans);
}
.alertsContainer{
  height: 20rem;
  overflow: auto;
}
.releaseContainer {
  background: var(--color-warning-yellow);
  border-radius: 7px;
  margin: 5px;
  padding: 10px 0 0 0;
  width: 400px;
  min-height: 25rem;
}
.popover{
  min-width:450px;
  min-height: 30rem;
}
.notificationContainer{
 margin-right: 100px !important;
}
