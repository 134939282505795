.tag-wrap {
  font-size: var(--font-size-small);
  display: flex;
  justify-content: space-between;
  height: 100px;
}
.site-tag {
  display: flex;
  height: 48px;
  background: var(--color-yellow);
  border: 1px solid var(--color-light-brown-2);
  border-radius: 4px;
  justify-content: space-between;
  padding: 12px;
  margin-right: 20px;
}

.no-selection-site-tag {
  display: flex;
  height: 48px;
  background: var(--color-white);
  border: 2px dashed var(--color-gray-3);
  border-radius: 4px;
  justify-content: space-between;
  padding: 12px;
  margin-right: 20px;
}

.message-align-wrap {
  margin-top: 20%;
  margin-left: 35%;
}

.title-wrap {
  font-family: var(--font-gene-sans-bold);
  font-size: var(--font-size-medium);
  padding-top: 8px;
}

.subtitle-wrap {
  font-family: var(--font-gene-sans);
  margin-top: 6px;
}

.default-message {
  font-size: var(--font-size-base);
  font-family: var(--font-gene-sans-bold);
  margin-left: 40px;
}

.delete-icon {
  margin: 5px 10px 0px 0px;
  width: 24px;
  height: 18px;
}

.title-wrap {
  width: 100%;
}

.info-icon {
  padding-left: 10px;
}

.tabs-navigation {
  float: right;
}

.radio-btn {
  height: 50px;
}
