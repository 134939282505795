$child-border-style: dashed;

.org-chart-container {
  border-radius: var(--border-radius);
  transition: all 0.5s ease-out;
  position: relative;
  .orgchart-container {
    border: 0;
    border-radius: 0;
    background: inherit;
    height: auto;
    padding: 24px;
  }
  .gne-org-chart {
    background: inherit;
    padding: 0;
  }
  .org-chart-item {
    width: 328px;
    margin: 0 auto;
    text-align: left;
    border: 1px solid var(--color-gray);
    height: 140px;
    box-shadow: none;
  }
}
.org-chart-container {
  .svg-chart-container {
    width: 100%
  }
}
::-webkit-scrollbar {
  width: 8px;
  height: 5px;
}

.zoom-cta-wrapper {
  position: absolute;
  bottom: 12px;
  left: 16px;
}
.detail-wrapper {
  text-align: left;
  margin-top: -6px;
}

.card-title {
  text-align: left;
  font-size: var(--font-size-x-small);
  font-weight: bold;
  margin-bottom: 8px;
  overflow: hidden;
  width: 230px;
  &__child {
    color: var(--color-tertiary-blue);
    text-transform: capitalize;
  }
}
.card-alignment {
  display: flex;
}
.card-info {
  text-align: left;
  font-size: var(--font-size-x-small);
  color: silver;
  padding-right: 5px;
}
.card-detail {
  font-size: var(--font-size-x-small);
}
.card-icon {
  float: right;
  margin-right: 0;
}
