.menu-item {
  padding: 0px 15px;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  gap: 10px;
  color: var(--color-black);
}

.menu-item:hover {
  background-color: var(--color-gray-2);
}
