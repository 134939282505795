.inputCustom {
  width: 520px !important;
  height: 32px;
}

.MR-4 {
  margin-right: 10px;
}
.text-content {
  
  background: #faf6ef 0% 0% no-repeat padding-box;

  height: 80px;
     width: 520   ;
   
  justify-content: space-around;
 align-content: center;
  

border-radius: 4px;
  border: 1px solid #dcdcdc;
}

.custom-row-style {
  height: 40px;
}

.custom-table {
  width: 521px;
  height: 254px;
}

.custom-table-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Editable-container {
  height: 23px;
  border-radius: 4px;
}
.input-custom {
  height: 32px;

  border-radius: 1px !important;
}

.form-control {
  border-right: 0 !important;
  box-shadow: none !important;
  
  border-radius: 0.375rem 0 0 0.375rem !important;
 
  height: 32px;
}
.url-content{
  cursor: pointer !important;
}

.reset-icon {
  border-top: 1px solid #ced4da;
  border-bottom: 1px solid #ced4da;
  border-color: #ced4da;
  background-color: var(--color-white);
   
}


