
.editModal {
    font-size: var(--font-size-base);
    box-shadow: 0px 0px 4px #00000029;
    //border-radius: 4px;
    //opacity: 1;
    overflow: hidden;
}

.modalBody {
    overflow: auto;
    height: 300px;
}

.favoritesList {
    text-align: left;
    font-size: 14px;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}

.btnBgColor {
    background-color: #004677 !important;
    a {
        color: white !important;
    }
}

.modalBody::-webkit-scrollbar {
     width: 5px;
}

*::-webkit-scrollbar-track {
    background: #DCDCDC;
}

*::-webkit-scrollbar-thumb {
    background: #919698;
    border-radius: 20px;
    border: 3px solid var(--scroll-bar-bg-color);
}
