.three-dots {
  position: absolute;
  top: 244px;
  left: 435px;
  width: 16px;
  height: 5px;
  transform: matrix(0, -1, 1, 0, 0, 0);
  background: var(--color-gray-3) 0% 0% no-repeat padding-box;
}
.no-dimension-value {
  text-align: center;
  vertical-align: middle;
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 0;
}
.ecosystemName {
  text-align: center;
}
.expandMyViewSidebar {
  background: var(--color-light-brown) 0% 0% no-repeat padding-box;
  opacity: 1;
  padding: 20px;
  height: 96%;
  margin-top: 15px;
}
.expandMyViewSidebarScrollableDiv {
  height: 100%;
  overflow-y: auto;
}
.expandMyViewSidebarHeading {
  font-size: 16px;
  font-weight: bold;
}
.expandMyViewSidebarDiv {
  margin-top: 24px;
}
.expandMyViewSidebarLabel {
  font-size: 12px;
  margin-bottom: 10px;
}
.expandMyViewSidebarValue {
  font-size: 14px;
  margin-bottom: 0;
}
.expandMyViewSidebarTitle {
  font-size: 20px;
  font-weight: bold;
}
.scrollable-table-container {
  height: 430px;
}

.card-title-tile {
  width: calc(100% - 80px);
}

.card-title-span {
  font-size: 15px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-title-div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.card-title-wrap {
  display: flex;
  justify-content: space-between;
}
.viewMoreButton {
  text-align: center;
  color: var(--color-tertiary-blue);
  cursor: pointer;
  font-weight: bold;
  font-size: 14px;
}
.no-dimension-container {
  margin: 6em 0;
}
.no-dimension-tile {
  height: 65%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  width: 100%;
}
.scrollable-table-div {
  width: 100%;
  // overflow-y: auto;
  flex-grow: 1;
}
.tile-container-drag-drop-container > div {
  justify-content: space-between;
}
.disclaimer-text {
  position: relative;
  bottom: -2.5rem;
  font-size: 14px;
  margin-bottom: 5px;
}
.iconalign {
  display: flex;
  justify-content: center;
}

.tile-title-wrap {
  width: 100%;
  overflow: visible;
  justify-content: space-between;
}
.cta-wrapper {
  display: flex;
  width: 70px;
}
.details-wrapper {
  width: 100%;
  // padding: 0 12px;
}
.content-wrapper {
  border-top: 1px solid var(--color-light-brown-2);
  width: 100%;
  padding: 2px 0 0;
  line-height: 100%;
}
.text-filed-right-border {
  border-right: 0 !important;
  box-shadow: none !important;
  // border: 0 !important;
  border-radius: 0.375rem 0 0 0.375rem !important;
}
.list-item {
  padding: 7px 16px;
  display: flex;
  align-items: center;
  font-size: var(--font-size-small);
  font-family: var(--font-gene-sans);
  min-width: 170px;
  cursor: pointer;
  &:hover {
    background: var(--color-light-brown);
  }
}
.context-menu-button {
  color: var(--color-black);
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
