.advanceSearchBox {
  padding: 10px 5px;
  border-radius: var(--border-radius);
  transition: all 0.5s ease-out;
  width: 100%;
}

.customer-tooltip {
  white-space: nowrap;
  text-wrap: nowrap;
  min-width: 400px;
  overflow-x: hidden;
}
.infoIcon {
  position: absolute;
  margin-top: 7px;
}
