.Toastify__toast-container--bottom-left {
  z-index: 10 !important;
  }

.warningMsgAlignment {
  background-color: #f2c641 !important;
  width: 798px;
  height: 64px;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0px;
  color:  #000 !important;
  margin-left: 80%;
}
