.select-dropdown {
  width: 100%;
}

.footer-align {
  margin-left: 12px !important;
  width: 94%;
}

.content-container {
  padding: 10px 0px 10px 10px;
}

.remove-icon-wrap {
  width: 19px;
  height: 22px;
}

.title-wrap {
  margin: 3px 0px 0px 8px;
  font-size: var(--font-size-medium);
  font-family: var(--font-gene-sans-bold);
}

.subtitle-wrap {
  font-family: var(--font-gene-sans);
  font-size: var(--font-size-small);
  padding-top: 8px;
}

.from-title-wrap {
  font-family: var(--font-gene-sans-bold);
  font-size: var(--font-size-small);
  padding-top: 8px;
}

.dropdown-wrap {
  margin: 30px 0 10px 0;
}

.label-wrap {
  margin-top: 20px;
}

.save-btn {
  width: 77px;
}

.dropdown-title {
  font-size: var(--font-size-x-small);
  padding-bottom: 6px;
  font-family: var(--font-gene-sans);
}

.content-foot {
  margin-top: 60px !important;
}
