.img_width {
  width: 155px !important;
  height: 175px !important;
}
.hdr {
  font-size: var(--font-size-normal);
  font-family: var(--font-gene-sans-bold);
  text-align: center;
}
.notes {
  font-size: var(--font-size-x-small);
  padding-left: 0 !important;
  text-align: left;
}
.notes span {
  font-family: var(--font-gene-sans-bold);
}
.selected_role_div {
  border: 1px solid var(--color-tertiary-blue) !important;
}
.role_div {
  padding: 7px;
  border-radius: 4px;
  border: 1px solid var(--color-gray-2);
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.3);
  margin-bottom: 0.35rem !important;
}
.role_div label {
  font-size: 12px; //var(--font-size-normal);
  font-family: var(--font-gene-sans-bold);
  margin-bottom: 0;
}
.role_div label:hover, .role_div input:hover, .role_div:hover {
  background-color: #e0e0e024;
  cursor: pointer;
}
.errorMsg {
  font-size: var(--font-size-small);
  color: var(--color-red);
  padding-left: 0 !important;
}
.position_rel {
  position: relative;
}
.spin {
  position: absolute;
  left: 0;
  margin-top: 7px;
}
.row_width {
  width: 25%;
}
@media (max-width: 992px) {
  .row_width {
    width: 40%;
  }
}