.filter-container {
  border-radius: var(--border-radius);
  background: var(--color-light-brown);
  margin-bottom: 30px;
  padding: 15px 15px 24px;
  transition: all 0.5s ease-out;
  position: relative;
  z-index: 1;
  .apply {
    width: 128px;
    margin-top: 24px;
  }
  &::after {
    content: '';
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 16px solid var(--color-light-brown);
    position: absolute;
    top: -16px;
    right: 34px;
  }
}

.filter-container-actions {
  border-radius: var(--border-radius);
  background: var(--color-light-brown);
  width: 340px;
  margin-bottom: 30px;
  padding: 15px;
  transition: all 0.5s ease-out;
  position: relative;
  z-index: 2;
  .apply {
    width: 128px;
    margin-top: 24px;
  }
  &.info {
    margin-top: 50px;
    &::after {
      content: '';
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      border-bottom: 16px solid var(--color-light-brown);
      position: absolute;
      top: -14px;
      right: 34px;
    }
  }
}

.LinkButton_link-button {
  .disabled {
    background-color: var(--color-tertiary-blue);
    border-color: var(--color-tertiary-blue);
  }
}

.isDisabled {
  pointer-events: none;
  opacity: 0.5;
}
.radioSelectionClass {
  width: 180px;
}

.reset-btn {
  color: var(--color-gray-3);
  pointer-events: none;
}

.filter-wrap {
  padding: 0px 10px;
  display: flex;
  justify-content: space-between;
}

.filters-title {
  font-size: var(--font-size-small);
  font-family: var(--font-gene-sans-bold);
}
