@import '../fonts/_gene-fonts';

// colors
:root {
  // colors
  --color-primary-blue: #4b80b1;
  --color-secondary-blue: #13294b;
  --color-tertiary-blue: #004677;
  --color-dark-blue: #041633;
  --color-light-blue: #203c67;
  --color-light-blue-2: #a2c1d9;
  --color-light-blue-3: #0b5a90;
  --color-light-blue-4: #e5ecf1;
  --color-light-blue-5: #0d4677;

  --gradient-primary: linear-gradient(90deg, #004677 0%, #4b80b1 100%);

  --color-light-brown: #f1f1f1;
  --color-light-brown-2: #dcdcdc;

  --color-gray: #919698;
  --color-gray-2: #e3e3e3;
  --color-gray-3: #858585;
  --color-gray-4: #cbcbcb;
  --color-disabled-gray: #f2f2f2;
  --color-dark-gray: #0000001f;
  --color-dark-blue: #115a90;
  --color-black: #000;

  --color-white: #fff;

  --color-green: #56a944;
  --color-green-2: #e0efdc;

  --color-red: #d04715;
  --color-red-2: #c23934;

  --color-yellow: #faf6ef;

  --shadow-card: 0px 0px 8px #00000029;
  --shadow-dropdown: 0px 0px 4px #0000003d;

  --color-warning-yellow: #faf6ef;

  // font
  // family
  --font-gene-sans: 'Gene-Sans', sans-serif;
  --font-gene-sans-bold: 'Gene-Sans-Bold', sans-serif;
  --font-gene-sans-light: 'Gene-Sans-Light', sans-serif;
  --font-gene-sans-thin: 'Gene-Sans-Thin', sans-serif;
  --font-gene-sans-italic: 'Gene-Sans-Italic', sans-serif;
  --font-gene-sans-bold-italic: 'Gene-Sans-Bold-Italic', sans-serif;
  --font-gene-sans-light-italic: 'Gene-Sans-Light-Italic', sans-serif;
  --font-gene-sans-thin-italic: 'Gene-Sans-Thin-Italic', sans-serif;

  --font-gene-condensed: 'Gene-Condensed', sans-serif;
  --font-gene-condensed-bold: 'Gene-Condensed-Bold', sans-serif;
  --font-gene-condensed-heavy: 'Gene-Condensed-Heavy', sans-serif;
  --font-gene-condensed-light: 'Gene-Condensed-Light', sans-serif;

  --font-gene-serif: 'Gene-Serif', sans-serif;
  --font-gene-serif-bold: 'Gene-Serif-Bold', sans-serif;
  --font-gene-serif-light: 'Gene-Serif-Light', sans-serif;
  --font-gene-serif-italic: 'Gene-Serif-Italic', sans-serif;
  --font-gene-serif-light-italic: 'Gene-Serif-Light-Italic', sans-serif;

  // size
  --font-size-xx-small: 0.625rem; // 10px
  --font-size-x-small: 0.75rem; // 12px
  --font-size-small: 0.875rem; // 14px
  --font-size-normal: 1rem; // 16px
  --font-size-base: 1.125rem; // 18px
  --font-size-medium: 1.25rem; // 20px
  --font-size-large: 1.375rem; // 22px
  --font-size-m-large: 1.5rem; //24px
  --font-size-x-large: 1.75rem; // 28px

  // border
  --border-radius: 4px;

  --gene-dropdown-h: 32px;
  --gene-dropdown-h-m: 30px;

  --animate-delay: 3s;

  // toastify
  --toastify-color-success: var(--color-green);
  --toastify-color-error: var(--color-red);
  --toastify-color-info: var(--color-light-blue-2);
  --toastify-font-family: var(--font-gene-sans);

  // tooltip
  --bs-tooltip-bg: var(--color-secondary-blue);

  //modal
  --bs-modal-width: 600px;
}
