$child-border-style: dashed;

.org-chart-container {
  background-color: var(--color-light-brown);
  border-radius: var(--border-radius);
  padding: 24px;
  overflow: auto;
  transition: all 0.5s ease-out;
  position: relative;
  .export {
    position: fixed;
    right: 50px;
    z-index: 1;
  }
  .orgchart-container {
    border: 0;
    border-radius: 0;
    background: inherit;
  }
  ul {
    ul {
      > li {
        &::after {
          border-left-style: $child-border-style;
          border-top-style: $child-border-style;
        }
        &::before {
          border-top-style: $child-border-style;
        }
        &:last-of-type {
          &::before {
            border-right-style: $child-border-style;
          }
        }
      }
    }
  }
}
.zoom-cta-wrapper {
  position: fixed;
  bottom: 80px;
}
.detail-wrapper {
  text-align: left;
  margin-top: -6px;
}
.org-chart-item {
  width: 308px;
  margin: 0 auto;
  text-align: left;
  border: 1px solid var(--color-light-brown) !important;
}
.card-title {
  text-align: left;
  font-size: var(--font-size-small);
  font-weight: bold;
  margin-bottom: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 230px;
  &__child {
    color: var(--color-tertiary-blue);
    text-transform: capitalize;
  }
}
.card-alignment {
  display: flex;
}
.card-info {
  text-align: left;
  font-size: var(--font-size-x-small);
  color: silver;
  padding-right: 5px;
}
.card-detail {
  font-size: var(--font-size-x-small);
}
.info-icon {
  position: absolute;
  right: 16px;
}

.node-icon-wrap {
  display: flex;
  float: right;
}

.hcp-icon {
  margin-top: 2px;
}
