.menuContainer {
  .menuItem {
    .menuItemLabel {
      color: var(--color-black);
    }
  }
  li.ant-menu-item-selected {
    a.item-link {
      color: var(--color-tertiary-blue);
    }
  }
}
.add-icon {
  position: relative;
  top: 2px;
}
