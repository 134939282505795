.gne-container {
  .nav-tabs {
    padding-left: 30px;
    padding-right: 30px;
    .nav-link {
      border: 0;
      font-family: var(--font-gene-sans-bold);
      color: var(--color-black);
      &.active {
        border-bottom: 4px solid var(--color-tertiary-blue);
        color: var(--color-tertiary-blue);
      }
    }
  }
}
