.customer-tag {
  border-radius: 20px;
  background: var(--color-green);
  margin-right: 5px;
  padding: 3px 8px 2px;
  text-transform: uppercase;
  color: #fff;
  display: inline-block;
  font-family: var(--font-gene-sans-bold);
  font-size: var(--font-size-xx-small);
  line-height: 12px;
}

.close-label-align {
  padding-top: 2px;
}
