.gne-dropdown {
  transition: all 0.5s ease-out;
  &.rmsc {
    height: var(--gene-dropdown-h);
    transition: all 0.5s ease-out;
    .dropdown-container {
      border-color: var(--color-light-brown-2);
    }
    .dropdown-container:focus-within {
      box-shadow: #004677 0 0 0 1px !important;
      border-color: #004677;
    }
    .dropdown-heading {
      height: var(--gene-dropdown-h);
    }
    &.disabled {
      .dropdown-container {
        background-color: var(--color-disabled-gray);
      }
    }
    .dropdown-content {
      z-index: 5;
      .options {
        font-size: var(--font-size-small);
        .select-item {
          margin-bottom: 0;
          &.selected {
            background: var(--color-light-brown);
            transition: all 0.5s ease-out;
          }
        }
      }
    }
    .gray {
      color: var(--color-gray-3);
    }
  }
}

.single-select {
  &.no-border-right {
    .gne {
      &__control {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
  &.no-border-left {
    .gne {
      &__control {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
  .gne {
    &__control {
      min-height: var(--gene-dropdown-h);
      height: var(--gene-dropdown-h);
      border-color: var(--color-light-brown-2);
      font-size: var(--font-size-small) !important;
      span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 50%;
      }
    }
    &__input-container {
      margin-top: 0;
      margin-bottom: 0;
      padding-top: 0;
      padding-bottom: 0;
      flex: inherit;
    }
    &__indicator-separator {
      background: transparent;
    }
    &__indicator {
      padding-top: 0;
      padding-bottom: 0;
    }
    &__clear-indicator {
      padding-right: 0;
      padding-left: 0;
    }
    &__dropdown-indicator {
      padding-left: 0;
    }
    &__option--is-focused,
    &__option--is-selected {
      background: var(--color-light-brown);
      color: inherit;
      transition: all 0.5s ease-out;
    }
    &__menu {
      transition: all 0.5s ease-out;
      font-size: var(--font-size-small);
      .drop-down-list {
        line-height: 110%;
      }
      z-index: 100;
    }
    &__control--is-disabled {
      pointer-events: visible !important;
      color: var(--color-gray-3) !important;
      cursor: not-allowed !important;
    }
  }
}
.custom-menu-portal {
  position: unset !important;
  .gne {
    &__menu-portal {
      top: 10%;
      left: auto;
      position: absolute;
    }
    // minMenuHeight is not working in GneAsyncPaginate
    &__menu-list {
      min-height: 300px;
    }
  }
}
