%hover {
  background-color: var(--color-tertiary-blue);
  color: var(--color-white);
  border-color: var(--color-tertiary-blue);
  transform: scale(1.015);
}
.btn {
  &.btn-gne {
    font-size: var(--font-size-small);
    font-family: var(--font-gene-sans-bold);
    padding: 5px 16px;
    transition: 0.3s;
    border-radius: 4px;
  }
  &.btn-primary {
    background-color: var(--color-tertiary-blue);
    border-color: var(--color-tertiary-blue);
    &:hover {
      @extend %hover;
    }
    &:disabled {
      background-color: var(--color-tertiary-blue);
      border-color: var(--color-tertiary-blue);
    }
  }
  &.btn-secondary {
    background-color: var(--color-light-brown);
    border-color: var(--color-light-brown);
    color: var(--color-tertiary-blue);
    &:hover {
      background-color: var(--color-light-brown-2);
      border-color: var(--color-light-brown-2);
      color: var(--color-tertiary-blue);
    }
    &.active {
      background-color: var(--color-tertiary-blue);
      border-color: var(--color-tertiary-blue);
      &:hover {
        color: #fff;
      }
    }
  }
  &.inactive {
    background-color: var(--color-tertiary-blue);
    color: var(--color-white);
    border-color: var(--color-tertiary-blue);
  }
  &.btn-outline-primary {
    &.btn-gne {
      color: var(--color-tertiary-blue);
      padding: 0.4em 1em;
      &__outline {
        border-radius: 4px;
        border: 1px solid var(--color-light-brown-2);
        &--add {
          &::before {
            content: '';
            background-image: url('../../images/svg/icon_add.svg');
            height: 12px;
            width: 12px;
            display: inline-block;
            margin-right: 8px;
          }
          &:hover {
            transform: scale(1.015);
            // transition: 0.5s background-color ease;
            &::before {
              content: '';
              background-image: url('../../images/svg/icon_add_white.svg');
            }
          }
        }
      }
      &:hover {
        @extend %hover;
      }
      &.w-70 {
        width: 71.5%;
      }
    }
  }
}
.ant-btn-primary {
  background-color: #004677 !important;
}
@media (max-width: 768px) {
  .btn {
    &.btn-gne {
      font-size: var(--font-size-x-small);
    }
  }
}
