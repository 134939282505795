.filter-container {
  border-radius: var(--border-radius);
  background: var(--color-light-brown);
  padding: 5px;
  transition: all 0.5s ease-out;
  position: relative;
  z-index: 2;

  &::after {
    content: '';
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 16px solid var(--color-light-brown);
    position: absolute;
    right: 34px;
  }
}

.filter-container-actions {
  border-radius: var(--border-radius);
  width: 340px;
  padding: 5px;
  transition: all 0.5s ease-out;
  position: relative;
  z-index: 2;

  &.info {
    &::after {
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      border-bottom: 16px solid var(--color-light-brown);
      position: absolute;
      right: 34px;
    }
  }
}

.LinkButton_link-button {
  .disabled {
    background-color: var(--color-tertiary-blue);
    border-color: var(--color-tertiary-blue);
  }
}

.isDisabled {
  pointer-events: none;
  opacity: 0.5;
}
.radioSelectionClass {
  width: 180px;
}

.reset-btn {
  color: var(--color-gray-3);
  pointer-events: none;
}

.filter-wrap {
  padding: 0px 10px;
  display: flex;
  justify-content: space-between;
}

.filters-title {
  font-size: var(--font-size-small);
  font-family: var(--font-gene-sans-bold);
}
