.kpi-sidebar {
  width: 100%;
  .ps-menu-root {
    padding-top: 24px;
  }
  .ps-menuitem-root {
    .ps-menu-button {
      padding-left: 15px;
      padding-right: 15px;
      span {
        white-space: initial;
      }
    }
  }
  aside {
    width: 100%;
  }
}
