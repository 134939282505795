$container-height: 324px;

.timeline-container {
  border: 1px solid var(--color-light-brown-2);
  height: $container-height;
  overflow: auto;
  position: relative;
  z-index: 0;
  font-family: var(--font-gene-sans);
  a.engagement-container {
    color: #000;
    &.active {
      color: var(--color-light-blue-3);
    }
  }
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
}
.body-container {
  min-height: calc($container-height - 3.4em);
  display: flex;
  position: relative;
  z-index: -1;
  &.no-data {
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
}
.opacity-one {
  opacity: 1 !important;
}
.timeline-header-container {
  display: flex;
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
}
.timeline-header {
  border-bottom: 1px solid var(--color-light-brown-2);
  padding: 14px;
  background-color: white;
  min-width: 86px !important;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.timeline-header,
.body {
  font-size: var(--font-size-x-small);
  text-align: center;
  transition: all 1s ease-in;
  min-width: 85px !important;
}
.body {
  min-height: calc($container-height - 4.5em);
  padding-top: 40px;
  background-image: linear-gradient(
    to bottom,
    #858585 40%,
    rgba(255, 255, 255, 0) 20%
  );
  background-position: center;
  background-size: 1px 18px;
  background-repeat: repeat-y;
  display: flex;
  flex-direction: column;
  &__red {
    background: repeating-linear-gradient(
        to bottom,
        transparent 0 8px,
        #c23934 0 16px
      )
      50%/2px 107% no-repeat;
  }
}
.icon-container {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: var(--color-light-blue-3);
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 1s ease-in-out;
}
.engagement-container {
  border: 1px solid var(--color-light-brown-2);
  border-radius: 20px;
  height: 32px;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  margin-left: -16px;
  align-self: flex-start;
  padding-right: 16px;
  position: relative;
  left: 50%;
  width: fit-content;
  cursor: pointer;
  transition: background-color 0.5s ease-in;
  &.engagement__disabled {
    color: var(--color-gray-3);
    pointer-events: none;
    .icon-container {
      background: var(--color-light-brown-2) !important;
      transition: all 1s ease-in-out;
    }
  }
  &.active {
    background: var(--color-light-blue-4);
    transition: background-color 0.5s ease-out;
    font-family: var(--font-gene-sans-bold);
  }
  &.small-width {
    font-size: var(--font-size-xx-small);
    .icon-container {
      width: 30px;
      height: 26px;
    }
    .icon {
      width: 14px;
      height: 9px;
    }
  }
}
.timeline-footer {
  height: 64px;
  background-color: var(--color-light-brown);
  border: 1px solid var(--color-light-brown-2);
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  z-index: 1;
  margin-bottom: 40px;
  transition: all 0.5s ease-out;
}
.footer-range {
  flex: 1;
  margin-left: 18px;
  margin-right: 18px;
  display: flex;
  transition: all 0.5s ease-out;
}
.timeline-container::-webkit-scrollbar-track-piece:start {
  margin-top: 47px;
}
.timeline-container::-webkit-scrollbar-track {
  background: transparent;
}
.engagement-grid-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
}
.icon-wrapper {
  vertical-align: middle;
}
.topic {
  width: 70%;
  display: inline-block;
}
.detail {
  font-family: var(--font-gene-sans);
  font-size: var(--font-size-xx-small);
  display: flex;
  padding-right: 0;
  white-space: nowrap;
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}
