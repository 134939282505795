.column {
  display: flex;
  align-items: center;
  padding-left: 0% !important;
  justify-content: flex-end;
}
.customer-column {
  display: flex;
  align-items: center;
  padding-left: 0% !important;
  font-family: var(--font-gene-sans);
  font-size: var(--font-size-x-small);
}
.col-group {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0% !important;
  column-gap: 0.43rem;
  span {
    font-family: var(--font-gene-sans);
    font-size: var(--font-size-x-small);
    text-wrap: nowrap;
  }
  button {
    font-family: var(--font-gene-sans);
    font-size: var(--font-size-x-small);
    text-wrap: nowrap;
  }
}
.pill {
  height: 23px;
  background: var(--color-white) 0% 0% no-repeat padding-box;
  border-radius: 20px;
  padding: 5px 3px;
  font-size: var(--font-size-x-small);
  position: relative;
  display: inline-block;
  line-height: 14px;
  margin-right: 8px;
  margin-top: 5px;
  transition: all 1s ease-in-out;
  &--newBg {
    background: var(--color-light-brown) 0% 0% no-repeat padding-box;
  }
}
.close-button {
  float: right;
  cursor: pointer;
  color: var(--color-gray-3);
  width: 8px;
  height: 8px;
  font-family: var(--font-gene-sans);
  margin-left: 5px;
  margin-right: 2px;
}
.pill-label {
  font-family: var(--font-gene-sans);
}
.customer-text-trends {
  font-family: var(--font-gene-sans-bold);
  position: relative;
  color: var(--color-tertiary-blue);
  font-size: var(--font-size-normal);
  font-weight: bold;
  margin-bottom: 3px;
  i {
    position: relative;
    top: 3px;
    left: 3px;
  }

  &.disabled {
    color: var(--color-gray-4);
  }
}
.customer-text-cv {
  position: relative;
  color: var(--color-black);
  i {
    position: relative;
    top: 3px;
    left: 3px;
  }
}
.advanced-title {
  font-family: var(--font-gene-sans);
  font-size: var(--font-size-x-small);
  color: var(--color-tertiary-blue);
  margin: 0px 0px 0px 6px;
  i {
    margin-left: 6px;
  }
  &:disabled {
    color: var(--color-gray-4);
  }
}
.advance-title-2 {
  font-family: var(--font-gene-sans);
  color: var(--color-white);
  margin: 0px 0px 0px 6px;
  font-size: var(--font-size-x-small);
  i {
    margin-left: 6px;
  }
  &:disabled {
    color: var(--color-gray-4);
  }
}
.advance-btn {
  font-family: var(--font-gene-sans);
  padding: 2px 0px 2px 2px !important;
  margin-right: 2rem;
  &:disabled {
    border: none;
    box-shadow: none;
    cursor: not-allowed;
  }
}
.text-disable {
  font-family: var(--font-gene-sans);
  color: var(--color-gray-4);
}
