.title {
  display: flex;
  flex-direction: column;
  text-align: center;
  color: var(--color-tertiary-blue);
  font-family: var(--font-gene-sans-bold);
}
.align-middle {
  align-self: center;
}
.detail {
  margin-top: 30px;
  label {
    font-family: var(--font-gene-sans);
    font-size: var(--font-size-normal);
  }
}
.insight-row {
  height: 50%;
}
