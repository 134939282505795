.info-icon {
  margin-left: 5px;
}
.radio_button {
  margin: 7px 5px 0px 5px;
  align-items: center;
}

.radio-btn-wrapper {
  align-self: flex-end;
  padding: 0 !important;
}

.radio_trend {
  padding: auto;
  align-items: center;
  width: 1em;
  height: 1em;
}

.radio_selection {
  display: flex;
}
.text-decorate-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.message-align-wrap {
  margin-top: 20%;
  margin-left: 35%;
}
.message-align-wrap-account {
  margin-top: 25%;
  margin-left: 20%;
}

.clearAll {
  padding-left: 50px;
  cursor: pointer;
  color: #004677;
  width: 70px;
}

.clearAllDisabled {
  padding-left: 50px;
  cursor: none;
  color: var(--color-gray);
  width: 70px;
}

.reasonPanelHeight {
  height: 500px;
}

.default-message {
  font-size: var(--font-size-base);
  font-family: var(--font-gene-sans-bold);
  margin-left: 40px;
}

.icon-alignment {
  margin-bottom: 3px;
}

.default-message-container {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.tag-wrap {
  font-size: var(--font-size-small);
  display: flex;
  justify-content: space-between;
  height: 100px;
}
.site-tag {
  display: flex;
  height: 48px;
  background: var(--color-yellow);
  border: 1px solid var(--color-light-brown-2);
  border-radius: 4px;
  justify-content: space-between;
  padding: 12px;
  margin-right: 20px;
}

.no-selection-site-tag {
  display: flex;
  height: 48px;
  background: var(--color-white);
  border: 2px dashed var(--color-gray-3);
  border-radius: 4px;
  justify-content: space-between;
  padding: 12px;
  margin-right: 20px;
}

.message-align-wrap {
  margin-top: 20%;
  margin-left: 35%;
}

.subtitle-wrap {
  font-family: var(--font-gene-sans);
  margin-top: 6px;
}

.default-message {
  font-size: var(--font-size-base);
  font-family: var(--font-gene-sans-bold);
  margin-left: 40px;
}

.delete-icon {
  margin: 5px 10px 0px 0px;
  width: 16px;
  height: 18px;
}

.info-icon {
  padding-left: 10px;
}
