.logs-select {
  width: 150px;
}

.dropdown-wrap {
  display: flex;
  padding: 10px 24px 20px 10px;
}

.dropdown-label {
  font-size: var(--font-size-x-small);
  font-family: var(--font-gene-sans);
  padding: 8px 13px 0 0;
}

.export-icon {
  cursor: pointer;
}

.table-title {
  color: var(--color-tertiary-blue);
}

.nextButton {
  height: 25px;
  background: var(--color-white) 0% 0% no-repeat padding-box;
  border: 1px solid var(--color-light-brown-2);
  border-radius: var(--border-radius);
  opacity: 1;
  margin: 0px 5px;
}
