.menu-btn {
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
}

.user-info {
  display: flex;
  justify-content: space-between;
  padding-right: 5px;
  .user-name {
    color: white;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-family: var(--font-gene-sans-bold);
  }
}

.nav-link-icon {
  margin-right: 15px;
  margin-top: -5px;
}
