.pill {
  height: 32px;
  background: var(--color-light-brown) 0% 0% no-repeat padding-box;
  border-radius: 20px;
  padding: 9px 12px;
  font-size: var(--font-size-x-small);
  position: relative;
  min-width: 85px;
  display: inline-block;
  line-height: 13px;
  margin-right: 8px;
  margin-top: 32px;
  transition: all 1s ease-in-out;
}
.close-button {
  float: right;
  margin-top: -2px;
  cursor: pointer;
  color: var(--color-gray-3);
  font-size: var(--font-size-normal);
  font-family: var(--font-gene-sans);
  margin-left: 12px;
}
.pill-metric {
  background: var(--color-white) 0% 0% no-repeat padding-box;
  border: 1px solid var(--color-light-brown-2);
  margin-top: 0;
  margin-bottom: 8px;
}
.number {
  font-size: var(--font-size-medium);
  float: left;
  padding-right: 8px;
  font-weight: bold;
  &__internal {
    color: var(--color-light-blue-3);
  }
  &__external {
    color: var(--color-green);
  }
}
