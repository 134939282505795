.info-icon {
  padding-left: 10px;
}

.disabled-row {
  color: var(--color-gray-3);
  pointer-events: none;
}

.nextButton {
  height: 25px;
  background: var(--color-white) 0% 0% no-repeat padding-box;
  border: 1px solid var(--color-light-brown-2);
  border-radius: var(--border-radius);
  opacity: 1;
  margin: 0px 5px;
}
