.close-button {
  float: right;
  margin-top: -2px;
  cursor: pointer;
  color: var(--color-gray-3);
  font-size: var(--font-size-normal);
  font-family: var(--font-gene-sans);
  margin-left: 12px;
}

.title {
  font-size: var(--font-size-base);
  font-family: var(--font-gene-sans);
  &__medium {
    font-size: var(--font-size-medium);
  }
  &__x-large {
    font-size: var(--font-size-x-large);
  }
  &__large {
    font-size: var(--font-size-large);
  }
  &__normal {
    font-size: var(--font-size-normal);
  }
  &__small {
    font-size: var(--font-size-small);
  }
  &__x-small {
    font-size: var(--font-size-x-small);
  }
  &__xx-small {
    font-size: var(--font-size-xx-small);
  }
  &__base {
    font-size: var(--font-size-base);
  }
  &__bold {
    font-family: var(--font-gene-sans-bold);
  }
  &__black {
    color: var(--color-black);
  }
  &__blue {
    color: var(--color-tertiary-blue);
  }
  &__white {
    color: #fff;
  }
  &__grey {
    color: var(--color-gray);
  }
  &__capitalize {
    text-transform: capitalize;
  }
  &__italic {
    font-family: var(--font-gene-sans-italic);
  }
}
.text {
  font-family: var(--font-gene-sans);
  &__grey {
    color: var(--color-gray);
  }
  &__blue {
    color: var(--color-tertiary-blue);
  }
  &__black {
    color: var(--color-black);
  }
  &__red {
    color: var(--color-red-2);
  }
  &__white {
    color: #fff;
  }
  &__dark-blue {
    color: var(--color-dark-blue);
  }
  &__secondary-blue {
    color: var(--color-secondary-blue);
  }
  &__uppercase {
    text-transform: uppercase;
  }

  &__secondary-blue {
    color: var(--color-secondary-blue);
  }
  &__light-blue {
    color: var(--color-light-blue-5);
  }
  &__bold {
    font-family: var(--font-gene-sans-bold);
  }
  &__capitalize {
    text-transform: capitalize;
  }
  &__small {
    font-size: var(--font-size-small) !important;
  }
  &__x-small {
    font-size: var(--font-size-x-small);
  }
  &__xx-small {
    font-size: var(--font-size-xx-small);
  }
  &__text-medium {
    font-size: var(--font-size-medium);
  }
  &__m-large {
    font-size: var(--font-size-m-large) !important;
  }
  &__base {
    font-size: var(--font-size-base);
  }
  &__normal {
    font-size: var(--font-size-normal);
  }
}
.sub-title {
  font-family: var(--font-gene-sans);
  font-size: var(--font-size-normal);
  &__x-small {
    font-size: var(--font-size-x-small);
  }
}
.divider {
  &-vertical {
    width: 1px;
    height: 20px;
    margin: 0 12px;
  }
  &-horizontal {
    border-bottom: 1px solid var(--color-light-brown-2);
    margin: 0 0 16px;
  }
  &__white {
    border: 1px solid var(--color-white);
  }
  &__grey {
    border: 1px solid var(--color-gray-2);
  }
}
.money {
  text-align: center;
  vertical-align: middle;
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 0;
  &__profit {
    color: var(--color-green);
  }
  &__loss {
    color: var(--color-red);
  }
}
.big-dot {
  background: white;
  height: 4px;
  width: 4px;
  border-radius: 50%;
  display: inline-block;
  margin: 0 4px;
  &__black {
    background: black;
  }
}
.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  &.center-page {
    width: 100vw;
    height: 80vh;
  }
  &.center-container {
    position: absolute;
    left: 50%;
    top: 50%;
  }
  &.inline {
    display: inline-block;
  }
}
.bg-color {
  &__green {
    background: var(--color-green) !important;
  }
  &__red {
    background: var(--color-red-2) !important;
  }
}
.pointer {
  cursor: pointer;
}
.Toastify__toast-body {
  font-size: var(--font-size-small);
}
.center-position {
  position: absolute;
  top: 39%;
  right: 38%;
}
.center-align-icon {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
}
.scroll-y-auto {
  overflow-y: auto;
}
.scroll-x-auto {
  overflow-y: auto;
}
.scroll-auto {
  overflow: auto;
}
.float-right {
  float: right;
}
.no-overflow {
  overflow: hidden;
}
.text-right {
  text-align: right;
}
.form-check-input-color {
  .form-check-input,
  .form-check-input:checked {
    background-color: var(--color-tertiary-blue);
    border-color: var(--color-tertiary-blue);
  }
}
.link {
  color: var(--color-tertiary-blue);
  cursor: pointer;
  font-weight: bold;
}
