.kpi-container {
  display: flex;
  align-items: baseline;
  transition: all 0.5s ease-out;
  z-index: 1;
}
.main-container {
  display: flex;
  flex-direction: column;
  background: var(--color-light-brown);
  margin-top: 2% !important;
  margin-bottom: 2%;
  background: var(--color-light-brown);
  padding: 15px 15px;
  border-radius: var(--border-radius);
}
.search-container {
  width: 100%;
  background-color: var(--color-white);
  margin-top: 2% !important;
  margin-left: 0.8rem !important;
  position: relative;
  border: 1px solid var(--color-light-brown-2);
  border-radius: 5px;
  z-index: 1;
  &.customerView {
    margin-left: 0rem !important;
    margin-top: 1% !important;
  }
  &.viewList {
    margin-top: 1% !important;
    width: 98%;
  }
  &.addCustomerView {
    background-color: var(--color-light-brown);
    margin-left: 0rem !important;
    width: 100%;
    border: none;
  }
}
.apply_button {
  display: flex;
  justify-items: center;
  margin-top: 21px;
}
.arrow {
  position: absolute;
  bottom: 100%;
  left: 80%;
  transform: translateX(-50%);
  width: 0 !important;
  height: 0;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 9px solid var(--color-white);
  &.centerWhite {
    left: 50%;
    border-bottom: 8px solid var(--color-white);
  }
  &.centerGray {
    border-bottom: 8px solid var(--color-light-brown);
    left: 50%;
  }
  &.leftgray {
    border-bottom: 8px solid var(--color-light-brown);
    left: 30%;
  }
}
.filter-container-scroll {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.filter-icon-container {
  margin-left: 9px;
}
.filter-container {
  overflow: auto;
  height: 59vh !important;
}
.filter-maxconatiner {
  overflow: auto;
  height: 65vh !important;
}
.info-icon {
  position: relative;
  top: 2px;
}

@media (min-height: 768px) and (orientation: landscape) {
  /* Styles for landscape mode with height greater than 768px */

  .filter-container {
    height: 50vh !important;
  }
  .filter-maxconatiner {
    height: 56vh !important;
  }
}
