
.acc_custom_popup {
  width: 209px;
  height: 240px;
  margin-left: 2px;
  .checkboxGroup {
    margin-top: 3px;
    display: flex;
    flex-direction: column;
    .ant-checkbox-wrapper {
      padding: 10px;
    }
  }
  .checkboxWrapper {
    margin: 5px 0;
  }
}
.co-verbiag{
  margin-left: 3px;
}

