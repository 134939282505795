.right-side-bar {
  background: var(--color-light-brown);
  width: 22%;
  min-height: calc(100vh - 48px); /* fall-back */
  padding: 0 32.5px 20px;
  section {
    padding: 40px 0 30px;
  }
}

.view-all-class-btn {
  border: 1 px solid var(--color-black);
  background-color: var(--color-white);
  height: 40px;
  padding: 10px;
  text-align: center;
}

.left-align {
  padding-left: 5px;
}
