.content-container {
  padding: 10px 0px 10px 10px;
}

.remove-icon-wrap {
  width: 25px;
  height: 21px;
}

.title-wrap {
  margin: 5px 0px 0px 5px;
  font-size: var(--font-size-medium);
  font-family: var(--font-gene-sans-bold);
}

.subtitle-wrap {
  font-family: var(--font-gene-sans);
  font-size: var(--font-size-small);
  padding-top: 8px;
}

.from-title-wrap {
  font-family: var(--font-gene-sans-bold);
  font-size: var(--font-size-small);
  padding-top: 8px;
}

.dropdown-wrap {
  margin: 30px 0 10px 0;
}

.label-wrap {
  margin-top: 20px;
}

.save-btn {
  width: 77px;
}

.dropdown-title {
  font-size: var(--font-size-x-small);
  padding-bottom: 6px;
  font-family: var(--font-gene-sans);
}

.content-foot {
  margin-top: 60px !important;
  width: 97%;
  margin-left: 12px !important;
}